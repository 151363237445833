'use client';

import { autoUpdate, flip, FloatingPortal, ReferenceType, useFloating } from '@floating-ui/react';
import { Menu, Transition } from '@headlessui/react';
import { m, Variants } from 'framer-motion';
import { omit } from 'lodash-es';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { mergeRefs } from 'react-merge-refs';
import { buttonAnimation } from '@/lib/animation';
import ArrowIcon from '@/assets/svg/ic-arrow.svg';
import searchIcon from '@/assets/icons/ic-search.png';
import bodyBackground from './bg-dropdown-body.png';
import footerBackground from './bg-dropdown-footer.png';
import checkedIcon from './Checked.png';
import clearIcon from './ic-clear.png';
import clearSelectedIcon from './ic-close.png';
import Input, { InputProps } from './ModalInput';
import uncheckIcon from './uncheck.png';

export interface DropdownItem {
  title?: string;
  value?: string;
  prefix?: string;
}

interface DropdownItemProps extends DropdownItem {
  onClick?: (value: string | undefined) => void;
  isActive?: boolean;
  multiselect?: boolean;
  itemChecked?: boolean;
}

export interface DropdownProps {
  id?: string;
  label?: string;
  selected?: string | undefined;
  multiselect?: boolean;
  multiValueSelectEd?: (string | null)[];
  items?: DropdownItem[];
  prefix?: React.ReactNode;
  dropdownClassName?: string;
  inputClassName?: string;
  wrapInputClassName?: string;
  wrapClassName?: string;
  activatorClassName?: string;
  hasSearchable?: boolean;
  emptyMessage?: string;
  placeholder?: string;
  queryPlaceholder?: string;
  onSelect?: (value: string | undefined, id: string | undefined) => void;
  inputProps?: InputProps;
  isRelative?: boolean;
  disabled?: boolean;
  canClearSelected?: boolean;
  inputValue?: string;
  onInputChange?: (value: string) => void;
  onScrollEnd?: () => void;
  isCanLoadMore?: boolean;
  loadingSearch?: boolean;
  focusOnOpen?: boolean;
  isStyleViolet?: boolean;
}

const itemVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

const Item = forwardRef<HTMLLIElement, DropdownItemProps>(function Item(
  { title, value, prefix, isActive, onClick, multiselect, itemChecked },
  ref,
) {
  const handleClick = useCallback(() => {
    if (!onClick) {
      return;
    }
    onClick(value);
  }, [onClick, value]);

  return (
    <m.li
      ref={ref}
      className={twMerge(
        'py-2 px-3',
        'transition-colors rounded-[8px] hover:cursor-pointer hover:bg-[#44238E] text-[11px] sm:text-[12px] xl:text-[13px] 2xl:text-[16px] w-full',
        multiselect && 'flex justify-start items-center',
        isActive && 'bg-[#44238E]',
      )}
      variants={itemVariants}
      onClick={handleClick}
      data-value={value}
    >
      {multiselect && itemChecked && <Image src={checkedIcon} alt="flyer" className="w-[24px]" />}
      {multiselect && !itemChecked && <Image src={uncheckIcon} alt="flyer" className="w-[24px]" />}
      <span className={`font-[600] ${multiselect ? 'pl-[10px]' : ''}`}>
        {prefix} {title}
      </span>
    </m.li>
  );
});

const Dropdown = forwardRef<HTMLInputElement, DropdownProps>(function ModalDropdown(
  {
    id = '',
    label,
    selected,
    multiselect,
    multiValueSelectEd,
    items,
    prefix,
    inputClassName,
    wrapClassName,
    wrapInputClassName,
    hasSearchable = false,
    emptyMessage,
    placeholder,
    queryPlaceholder,
    dropdownClassName,
    activatorClassName,
    inputProps,
    isRelative = true,
    disabled = false,
    canClearSelected = false,
    onSelect,
    inputValue,
    onInputChange,
    onScrollEnd,
    isCanLoadMore,
    loadingSearch,
    focusOnOpen,
    isStyleViolet,
  },
  ref,
) {
  const { t } = useTranslation();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState('');
  const [dropdownItems, setDropdownItems] = useState(items || []);
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => {
    if (disabled) {
      return;
    }
    setOpen((value) => !value);
  }, [disabled]);
  const [isScroll, setIsScroll] = useState<boolean>(false);

  const handleSelect = useCallback(
    (value: string | undefined) => {
      if (!onSelect) {
        return;
      }
      onSelect(value, id);
      if (!multiselect) {
        toggleOpen();
      }
    },
    [id, multiselect, onSelect, toggleOpen],
  );

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onInputChange) {
        onInputChange(e.currentTarget.value);
      } else {
        setQuery(e.target.value);
      }
      const searchText = (e.target.value || '').toLocaleLowerCase();
      const filterItems = searchText
        ? items?.filter((i) => {
            const lowerCaseTitle = i.title?.toLocaleLowerCase();
            return lowerCaseTitle?.includes(searchText);
          })
        : items;
      setDropdownItems(filterItems || []);
      setIsScroll(false);
    },
    [items, onInputChange],
  );

  const handleQueryClear = useCallback(() => {
    if (onInputChange) {
      onInputChange('');
    }
    setQuery('');
    setDropdownItems(items || []);
    setIsScroll(false);
  }, [items, onInputChange]);

  const handleSelectedClear = useCallback(() => {
    if (onSelect) {
      onSelect(undefined, id);
    }
    if (onInputChange) {
      onInputChange('');
    }

    setQuery('');

    searchInputRef.current?.focus();
  }, [id, onInputChange, onSelect]);

  useEffect(() => {
    if (inputValue && onInputChange) {
      const searchText = inputValue.toLocaleLowerCase();
      const filterItems = items?.filter((i) => {
        const lowerCaseTitle = i.title?.toLocaleLowerCase().replaceAll(/\s/g, '');
        return lowerCaseTitle?.includes(searchText.replaceAll(/\s/g, ''));
      });
      setDropdownItems(filterItems || []);
    }
  }, [inputValue, items, onInputChange]);

  useEffect(() => {
    if (items) {
      setDropdownItems(items);
    }
  }, [items]);

  const renderTitle = useMemo(() => {
    if (multiselect) {
      if (multiValueSelectEd?.includes('all') || multiValueSelectEd?.length === 0) {
        return items?.find((i) => i.value === 'all')?.title;
      }
      return multiValueSelectEd
        ?.filter((i) => i !== 'all')
        .map((a) => items?.find((i) => i.value === a)?.title)
        .join(', ');
    }

    const item = items?.find((i) => i.value === selected);
    return item?.title || '';
  }, [items, multiValueSelectEd, multiselect, selected]);

  // handler scroll end
  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const dropdown = e.target as HTMLDivElement;
      const bottom =
        dropdown && dropdown.scrollTop + dropdown.clientHeight === dropdown.scrollHeight;
      setIsScroll(true);
      if (bottom && onScrollEnd && isCanLoadMore) {
        onScrollEnd();
      }
    },
    [onScrollEnd, isCanLoadMore],
  );

  const middleware = useMemo(() => {
    return [flip()];
  }, []);

  const whileElementsMounted = useCallback(
    (reference: ReferenceType, floating: HTMLElement, update: () => void) => {
      floating.style.width = `${(reference as HTMLElement).clientWidth}px`;
      return autoUpdate(reference, floating, update);
    },
    [],
  );

  const { refs, floatingStyles, placement } = useFloating({
    open,
    // onOpenChange: toggleOpen,
    placement: 'bottom',
    whileElementsMounted,
    middleware,
  });

  const position = placement.startsWith('top') ? 'top' : 'bottom';

  const [animating, setAnimating] = useState(false);
  const beforeEnter = useCallback(() => {
    setAnimating(true);
  }, []);
  const afterLeave = useCallback(() => {
    setAnimating(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if ((refs.reference.current as HTMLElement | null)?.contains(event.target as Node)) {
        return;
      }
      if (!refs.floating.current?.contains(event.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs.floating, refs.reference]);

  const inputRef = useMemo(() => {
    return inputProps?.ref ? mergeRefs([ref, inputProps.ref]) : ref;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (open && hasSearchable && focusOnOpen) {
      const timer = setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }, 0);

      return () => clearTimeout(timer);
    }
  }, [open, focusOnOpen, hasSearchable]);

  return (
    <Menu
      as="div"
      className={twMerge(isRelative && 'relative', 'inline-block w-full z-[2]', wrapClassName)}
    >
      <div
        ref={refs.setReference}
        role="button"
        aria-hidden
        onClick={toggleOpen}
        className={twMerge('h-[inherit]', activatorClassName)}
      >
        <Input
          ref={inputRef}
          inputPrefix={prefix}
          value={renderTitle || ''}
          readOnly
          isStyleViolet={isStyleViolet}
          inputSuffix={
            disabled ? undefined : (
              <m.span
                initial={{ rotate: 0 }}
                animate={{ rotate: open ? 0 : '180deg' }}
                exit={{ rotate: 0 }}
                transition={{ ease: 'easeIn', duration: '0.25' }}
              >
                <ArrowIcon className="w-[20px] md:w-[23px]" />
              </m.span>
            )
          }
          wrapClassName={twMerge(
            disabled ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer',
            'h-[inherit] z-[5]',

            wrapInputClassName,
          )}
          className={twMerge(
            disabled ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer',
            inputClassName,
          )}
          placeholder={placeholder}
          label={label}
          type="text"
          {...omit(inputProps, ['ref', 'onChange', 'name'])}
        />
      </div>
      {(open || animating) && (
        <FloatingPortal>
          <Transition
            ref={refs.setFloating}
            style={floatingStyles}
            show={open}
            appear
            unmount
            className={twMerge(
              'rounded-md focus:outline-none px-5 z-[301] sm:px-7 text-white [&_::-webkit-scrollbar-thumb]:[background:#33F3F7]',
              dropdownClassName,
            )}
            beforeEnter={beforeEnter}
            afterLeave={afterLeave}
            enter="transition-opacity ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-in duration-75"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={twMerge(
                'relative w-full p-1',
                position === 'top' ? 'pt-[10%]' : 'pb-[10%]',
              )}
            >
              {hasSearchable && (
                <div className="sticky mt-1 top-0 w-full text-[12px] sm:text-[13px]">
                  <div className="rounded-[8px] border-[#37F9F7] border-[1px] border-solid px-3 py-2 flex items-center">
                    <Image src={searchIcon} alt="Search" className="w-[22px] mr-2" />
                    <input
                      placeholder={queryPlaceholder}
                      className="w-full bg-transparent focus:outline-none placeholder:text-[#7BFAFC]"
                      onChange={handleSearchChange}
                      value={inputValue || query}
                      ref={searchInputRef}
                    />
                    {(inputValue || query) && (
                      <m.button type="button" {...buttonAnimation} onClick={handleQueryClear}>
                        <Image src={clearIcon} alt="Search" className="w-[15px] ml-2" />
                      </m.button>
                    )}
                  </div>
                  {selected && canClearSelected ? (
                    <button
                      className="flex items-center justify-between text-white px-3 my-1 w-full"
                      // {...buttonAnimation}
                      onClick={handleSelectedClear}
                      type="button"
                    >
                      <span>{t('common:label.clear_selection')}</span>
                      <Image
                        src={clearSelectedIcon}
                        alt="Clear selection"
                        className="max-w-[24px]"
                      />
                    </button>
                  ) : (
                    <span />
                  )}
                </div>
              )}
              {loadingSearch && !isScroll ? (
                <div className="w-full h-[150px] flex flex-col justify-center items-center">
                  <div className="loading-spin" />
                </div>
              ) : (
                <Menu.Items
                  id="list-menu-items"
                  className="menu-items list-none overflow-y-auto max-h-[120px] xl:max-h-[150px] sm:max-h-[100px] my-1"
                  onScroll={handleScroll}
                  static
                >
                  {dropdownItems.length > 0 ? (
                    <>
                      {dropdownItems.map((item, index) => {
                        const keyMap = `${item?.value || 'item'}-${index}`;
                        if (item.value === 'break-line') {
                          return (
                            <div key={keyMap} className="px-0.5 py-0.5">
                              <Menu.Item>
                                <span
                                  className={twMerge(
                                    'py-2 px-3 text-[#E7D5FF] text-[12px] font-bold',
                                    'transition-colors w-full',
                                  )}
                                >
                                  {item.title}
                                </span>
                              </Menu.Item>
                            </div>
                          );
                        }
                        return (
                          <div
                            key={keyMap}
                            className={`px-0.5 py-0.5 ${
                              multiselect &&
                              ((multiValueSelectEd && multiValueSelectEd.length === 0) ||
                                multiValueSelectEd?.includes(
                                  typeof item.value === 'string' ? item.value : '',
                                ))
                                ? 'select text-[#7BFAFC]'
                                : ''
                            }`}
                          >
                            <Menu.Item>
                              <Item
                                {...item}
                                multiselect={multiselect}
                                itemChecked={
                                  (multiValueSelectEd && multiValueSelectEd.length === 0) ||
                                  multiValueSelectEd?.includes(
                                    typeof item.value === 'string' ? item.value : '',
                                  ) ||
                                  false
                                }
                                onClick={handleSelect}
                                isActive={selected === item.value}
                              />
                            </Menu.Item>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="text-[13px] px-3 text-center">
                      {emptyMessage || t('common:label.not_found')}
                    </div>
                  )}
                </Menu.Items>
              )}

              <div
                className={twMerge(
                  placement,
                  'body-background absolute left-0 w-full h-full z-[-1] top-0',
                )}
              >
                {position === 'top' && (
                  <Image src={footerBackground} alt="" className="h-[11%] w-full rotate-180" />
                )}
                <Image src={bodyBackground} alt="" className="h-[90%] w-full" />
                {position === 'bottom' && (
                  <Image src={footerBackground} alt="" className="h-[10%] w-full" />
                )}
              </div>
            </div>
          </Transition>
        </FloatingPortal>
      )}
    </Menu>
  );
});

export default Dropdown;
