import React, { createElement, forwardRef, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

type ModalHeadingProps = JSX.IntrinsicElements['h5'] & {
  className?: string;
  as?: string;
  children: React.ReactNode;
};

export default forwardRef<HTMLHeadingElement, ModalHeadingProps>(function ModalHeading(
  { children, className, as = 'h5' },
  ref,
) {
  const renderProps = useMemo(
    () => ({
      className: twMerge('font-semibold text-[14px] sm:text-[16px] leading-6', className),
      ref,
    }),
    [className, ref],
  );

  return createElement(as, renderProps, children);
});
