'use client';

import { useCallback, useEffect, useRef } from 'react';
import { decode } from 'blurhash';

export type Props = React.CanvasHTMLAttributes<HTMLCanvasElement> & {
  hash: string;
  height?: number;
  punch?: number;
  width?: number;
};

export default function BlurhashCanvas(props: Props) {
  const { punch, hash, width = 128, height = 128, ...rest } = props;
  const ref = useRef<HTMLCanvasElement>(null);

  const draw = useCallback(() => {
    if (!ref.current) {
      return;
    }
    const canvas = ref.current;
    const pixels = decode(hash, width, height, punch);
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    const imageData = ctx.createImageData(width, height);
    imageData.data.set(pixels);
    ctx.putImageData(imageData, 0, 0);
  }, [hash, height, punch, width]);

  useEffect(() => {
    draw();
  }, [draw]);

  return <canvas ref={ref} {...rest} width={width} height={height} />;
}
