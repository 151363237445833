import { createElement, forwardRef, useMemo } from 'react';
import Image from 'next/image';
import { twMerge } from 'tailwind-merge';
import BlurhashCanvas from './BlurhashCanvas';

export interface ImageWithBlurProps {
  wrapAs?: keyof JSX.IntrinsicElements;
  wrapClassName?: string;
  src: string;
  alt: string;
  blurHash?: string | null;
  sizes?: string;
  objectFit?: 'fill' | 'contain';
}

export default forwardRef<HTMLElement, ImageWithBlurProps>(function ImageWithBlur(props, ref) {
  const { wrapClassName, alt, src, blurHash, objectFit, sizes = '100vw', wrapAs = 'div' } = props;

  const renderChildren = useMemo(() => {
    return (
      <>
        {blurHash && (
          <BlurhashCanvas hash={blurHash} punch={1} className="object-fill w-full h-full" />
        )}
        <Image
          alt={alt}
          src={src}
          fill
          className={twMerge(
            objectFit === 'contain' || (!objectFit && blurHash) ? 'object-contain' : 'object-fill',
          )}
          sizes={sizes}
        />
      </>
    );
  }, [alt, blurHash, objectFit, sizes, src]);

  const renderProps = useMemo(() => {
    return { className: twMerge('overflow-hidden', wrapClassName), ref };
  }, [wrapClassName, ref]);

  return createElement(wrapAs, renderProps, renderChildren);
});
