import Image, { StaticImageData } from 'next/image';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { m } from 'framer-motion';
import defaultAvatar from '@/assets/images/default-avatar.png';
import frameDefault from '@/assets/images/default-frame.png';

type Props = {
  frame?: string | StaticImageData | null;
  avatar?: string | StaticImageData | null;
  className?: string;
  avatarClassName?: string;
  frameClassName?: string;
  onClick?: () => void;
};

export default function FrameAvatar({
  frame,
  avatar,
  className,
  avatarClassName,
  frameClassName,
  onClick,
}: Props) {
  const padding = '17%';

  return (
    <m.div
      className={twMerge(
        '',
        className,
        'relative flex items-center justify-center aspect-square box-border',
      )}
      onClick={onClick}
    >
      {/* Avatar */}
      <div style={{ padding }} className="w-full h-full">
        <Image
          src={avatar || defaultAvatar}
          alt="avatar"
          width={1000}
          height={1000}
          quality={100}
          className={twMerge('object-cover', avatarClassName, 'w-full h-full rounded-full')}
        />
      </div>

      {/* Frame */}
      <Image
        src={frame || frameDefault}
        alt="frame"
        width={1000}
        height={1000}
        quality={100}
        className={twMerge('absolute inset-0 w-full h-full', frameClassName)}
      />
    </m.div>
  );
}
