import React, { createElement, useMemo, forwardRef } from 'react';
import Image, { StaticImageData } from 'next/image';
import { twMerge } from 'tailwind-merge';
import { m } from 'framer-motion';

export type BgImageProps = JSX.IntrinsicElements['div'] & {
  wrapAs?: keyof JSX.IntrinsicElements;
  src: StaticImageData | string;
  alt: string;
  priority?: boolean;
  children?: React.ReactNode;
  wrapClassName?: string;
  className?: string;
  imageClassName?: string;
};

const BgImage = forwardRef<HTMLElement, BgImageProps>(function BgImage(props, ref) {
  const {
    alt,
    src,
    wrapClassName,
    imageClassName,
    className,
    priority = true,
    children,
    wrapAs = 'div',
    ...rest
  } = props;

  const renderChildren = useMemo(() => {
    return (
      <>
        <Image
          alt={alt}
          src={src}
          priority={priority}
          fill={typeof src === 'string'}
          className={twMerge('z-0', imageClassName)}
          quality={90}
        />
        {children && (
          <div {...rest} className={twMerge('absolute inset-0', className)}>
            {children}
          </div>
        )}
      </>
    );
  }, [alt, children, className, imageClassName, priority, rest, src]);

  const renderProps = useMemo(
    () => ({ className: twMerge('relative', wrapClassName), ref }),
    [wrapClassName, ref],
  );

  return createElement(wrapAs, renderProps, renderChildren);
});

export const MotionBgImage = m(BgImage);

export default BgImage;
